.header {
  .nav-bar {
    .logo-text {
      float: left;

      a {
        font-family: "Oswald", sans-serif;
        text-decoration: none;
        font-size: 3.5rem;
        font-style: italic;
        font-weight: 700;
        height: 8rem;
        line-height: 8rem;
        position: absolute;
        text-transform: uppercase;
        transition-duration: 500ms;
        transition-property: line-height, opacity;
      }

      .short-text {
        display: none;
      }

      @media only screen and (max-width: 500px) {
        .short-text {
          display: block;
        }

        .full-text {
          display: none;
        }
      }
    }

    .logo {
      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
  }
}

/* Compacted Height */
.header-compact {
  .logo-text {
    a {
      height: 6rem;
      line-height: 6rem;

      /* Hide animation */
      opacity: 0;
    }
  }
}

body.mobile {
  /* Only display text on mobile */
  .header {
    .nav-bar {
      .logo {
        display: none;
      }

      .logo-text {
        a {
          color: #333333;
        }
      }
    }
  }
}

// Add padding to top of page on desktop
@media only screen and (min-width: 961px) {
  .main {
    padding-top: 75px;
  }
}
