/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v20-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v20-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v20-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/oswald-v40-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/oswald-v40-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v40-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v40-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v40-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v40-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}

h1, h2 {
  font-family: "Oswald", sans-serif;
}

h1 {
  font-style: italic;
  font-size: 9rem;
  letter-spacing: 8px;
  line-height: 1.6;
}

@media only screen and (max-width: 1120px) {
  h1 {
    font-size: 6rem;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 4.5rem;
    letter-spacing: 4px;
  }
}

h3 {
  margin-bottom: 0.5rem;
}

.grid h3, .projects-grid h2 {
  font-size: 1.5rem;
}
