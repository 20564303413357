@import "libraries";
@import "components";

.upcase {
  text-transform: uppercase;
}

*:focus {
  outline: thin dotted !important;
}

.overlay-navigation-wrapper {
  display: none;
  &.active {
    display: block;
  }
}

.wildcat {
  .wildcat__website {
    margin-top: -2rem;
  }
}

section {
  margin-bottom: 2em;
}
