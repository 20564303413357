@import 'typography';
@import 'navbar';
@import 'overlay-nav';
@import 'tsm-slides';
@import 'project-page';
@import 'footer';
@import 'about_us';

.image-header.thumbnail {
  height: 50vh;
  min-height: 400px;
}

.featured-media .image-header.thumbnail {
  height: 100%;
}

// Intro image on homepage
.home-header img, .project-header img {
  height: 50rem;
  width: 100%;
  object-fit: cover;
}

.partners-content li {
  margin-bottom: 2rem;
}

.logo-story .logos {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  picture {
    width: 50%;
  }
}
