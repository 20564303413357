footer {
  font-size: 1.3rem;
  margin: 2rem 0 -2rem 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #666666;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.right {
    text-align: right;
  }

  .social-links {
    a {
      margin-right: 0.75rem;
    }
  }
}
