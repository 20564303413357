.project-header {
    .caption-over-outer {
        background: rgba(0,0,0,0.45);
    }
}

@media only screen and (max-width: 600px) {
    .project-header {
        .caption-over-outer {
            padding: 0;
        }
    }
}
