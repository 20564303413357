// This is the Hero Section component
.tms-slides {
  @media only screen and (max-width: 768px) {
    .tms-caption {
      white-space: normal !important;
    }
  }
}

// Hero section on projects
.project-header .tms-content {
  background: rgba(0,0,0,0.5);
}
